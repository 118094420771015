.mainContainer {
  display: flex;
  flex-direction: column;
  min-width: 600px;
  min-height: 530px;
}

.container {
  padding: 40px 30px;
}

.addCSVMessage {
  display: flex;
  padding: 20px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  border-bottom: 1px #d7d7d7 solid;
}

.dropZoneContainer {
  padding: 60px;
  text-align: center;
  border: 2px #cbcbd7 dashed;
  cursor: pointer;
}

.dropZoneContainerFilled {
  padding: 60px;
  text-align: center;
  border: 2px var(--radium-blue) dashed;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10%;
  min-height: 84px;
  overflow-wrap: break-word;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 8px 20px;
}
