.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
  padding: 24px;
  color: #000000de;
  font-size: 16px;
}

.container span {
  font-weight: 500;
  font-size: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.title {
  display: flex;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 20px;
}

.logoutIcon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-top: 7px;
}

.warningContainer > div > button {
  height: 20px;
}

.warningContainer > div > div > div > div:last-child {
  display: flex;
  align-items: center;
}

.warning {
  flex-grow: 1;
}
