.modalOverlay,
.noBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  backdrop-filter: blur(7px);
}

.noBackground {
  backdrop-filter: blur(0.5px);
}

.loader {
  position: relative;
  width: 48px;
  height: 48px;
  color: var(--radium-blue);
  background: #4ea381;
  transform: rotateX(65deg) rotate(45deg);
  transform: perspective(200px) rotateX(65deg) rotate(45deg);
  animation: layers1 1s linear infinite alternate;
}

.loader::after {
  position: absolute;
  background: #ffffff;
  animation: layer-tr 1s linear infinite alternate;
  content: '';
  inset: 0;
}

@keyframes layers1 {
  0% {
    box-shadow: 0 0 0 0;
  }

  90%,
  100% {
    box-shadow: 20px 20px 0 -4px;
  }
}

@keyframes layer-tr {
  0% {
    transform: translate(0, 0) scale(1);
  }

  100% {
    transform: translate(-25px, -25px) scale(1);
  }
}
