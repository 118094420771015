.spanColor {
  color: #344767;
}

.title {
  font-weight: bold;
}

.content {
  font-size: 13px;
}

.link {
  text-decoration: underline #344767;
  cursor: pointer;
}
