:root {
  --middle-input-padding: 10px;
  --around-input-padding: 20px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.dropDownAndDatePicker div div div,
.dropDownAndDatePicker input {
  padding-block: 9px;
}

.addUserMessage {
  padding: 20px;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px #d7d7d7 solid;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1150px;
  padding-bottom: 14px;
}

.firstInputsColumn,
.lastInputsColumn,
.secondInputsColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0 10px;
  padding: 35px var(--middle-input-padding) 0 var(--around-input-padding);
}

.firstInputsColumn div p,
.lastInputsColumn div p,
.secondInputsColumn div p {
  margin-bottom: 13px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 9px;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
