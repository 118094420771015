.subContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 80%;
  margin-bottom: 3%;
}

.hoursDedicatedContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 500px;
  height: fit-content;
  padding: 8px;
  color: white;
  font-weight: 500;
  background-color: #7b809a;
  border-radius: 5px;
}

.hoursDedicatedLabel {
  color: white;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.hoursDedicated {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.splitContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 80%;
}

.elementContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin: 20px 0;
}

.notesContainer {
  width: 80%;
  margin-top: 5%;
}

.skillsContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 80%;
}

.profileSkillSpan {
  display: flex;
  color: white;
  font-size: 12px;
  background-color: #459474;
  border-radius: 14px;
  pointer-events: none;
  padding-block: clamp(5px, 2vw, 7px);
  padding-inline: clamp(13px, 2vw, 17px);
}
