.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.textContainer span {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}

.textContainer p {
  margin: 22px 0;
  color: #00000099;
  font-size: 14px;
}

.buttonContainer > button {
  background-color: #4f987b;
}

.buttonContainer > button:hover {
  background-color: #478c71;
}

.emptyNotifications {
  width: 100%;
  margin-left: 70px;
}
