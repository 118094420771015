.modalContainer {
  display: flex;
  flex-direction: column;
  height: 630px;
  overflow-y: scroll;
}

.headerAddMember {
  font-weight: 500;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  border-bottom: 1px solid #0000000f;
}

.headerAddMember p {
  margin-bottom: 20px;
  padding-left: 20px;
}

.contentContainer {
  display: flex;
  margin: 5%;
}

.helperDedication {
  width: 42%;
}

.memberForm {
  display: flex;
  align-self: center;
  justify-content: center;
  text-align: center;
}

.topContainer {
  height: 60px;
  margin-bottom: 38px;
}

.inputsContainer {
  display: flex;
  gap: 40px;
}

.inputsContainer > div {
  width: 50%;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
}

.datePickers {
  display: flex;
  gap: 40px;
  justify-content: space-around;
}

.warningContainer {
  display: flex;
  justify-content: flex-start;
  height: 40px;
}

.warningMessage {
  display: flex;
  gap: 14px;
  align-items: center;
  color: #3f3604;
}

.warningText {
  margin: 13px 0 10px;
}
