.emptyMember > div > p {
  margin: 0;
  color: #00000099;
  font-weight: 400;
  font-size: 14;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.emptyMember {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.emptyMember > :first-child {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.messageContainer {
  margin-bottom: 30px;
}

.addMemberButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.addMemberButton > button {
  background-color: #4f987b;
}

.addMemberButton > button:hover {
  background-color: #478c71;
}
