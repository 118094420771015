.modalContainer {
  display: flex;
  flex-direction: column;
  height: 275px;
  overflow-y: hidden;
}

.header {
  font-weight: 500;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  border-bottom: 1px solid #0000000f;
}

.header p {
  margin-bottom: 20px;
  padding-left: 20px;
}

.contentContainer {
  margin: 5%;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}

.datePickers {
  display: flex;
  gap: 40px;
  justify-content: space-around;
}

.datePicker div p {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  margin: 7px;
}
