.modalContainer {
  display: flex;
  flex-direction: column;
  width: 542px;
  height: 540px;
  overflow-y: hidden;
}

.headerGrowthPlan {
  font-weight: 500;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  border-bottom: 1px solid #0000000f;
}

.headerGrowthPlan p {
  margin-bottom: 20px;
  padding-left: 20px;
}

.contentContainer {
  display: flex;
  margin: 3% 5%;
}

.growthPlanForm {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.growthPlanForm form {
  width: 100%;
}

.topContainer {
  display: flex;
  gap: 40px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.inputsContainer {
  display: flex;
  gap: 40px;
}

.inputsContainer > div {
  width: 100%;
}

.textArea > div > div > div {
  background-color: #f6f6f6;
  box-shadow: 0 0 10px 0 #0000001e inset;
}

.textArea > div > div > p {
  margin-bottom: 20px;
}

.bottomContainer {
  display: flex;
  gap: 40px;
  width: 100%;
  margin-bottom: 15px;
}

.objectiveDate {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
}
