.container {
  width: 50%;
  height: fit-content;
  padding: 20px;
  background-color: #4f987b1a;
  border-radius: 8px;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.container h1 {
  margin-bottom: 34px;
  color: var(--radium-blue);
  font-weight: bolder;
  font-size: 20px;
}

.skill {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 200px;
}

.skill span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  color: #ffffff;
  background-color: #4f987b;
  border-radius: 50%;
}

.skill h3 {
  color: #000000b8;
  font-size: 14px;
}
