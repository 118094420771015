.container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0.5rem;
  border-bottom: 1px solid #dadada;
}

.header > div {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 24px;
}

.subtitle {
  font-weight: 300;
  font-size: 15px;
  line-height: 0;
}

.body {
  display: grid;
  flex: 1;
  grid-template-columns: 1.2fr 1fr 1fr;
  gap: 2rem;
  padding: 2rem;
}

.filtersAndList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
}

.filterButtons {
  display: flex;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterDropdown {
  height: 30px;
  color: var(--radium-blue);
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.listContainer {
  padding: 1.5rem 0.75rem;
  font-size: small;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.listContainer table {
  width: 100%;
}

.listContainer th {
  padding-bottom: 1rem;
  color: #7c8099;
  font-weight: 500;
  text-align: left;
}

.listContainer td {
  padding: 0.25rem 0;
  color: #7b809a;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 2px;
}

.listContainer tr {
  cursor: pointer;
}

.comparisonCardContainer {
  display: flex;
  flex-grow: 1;
  gap: 2rem;
  background-color: #99ffba;
}

.footer {
  margin-left: auto;
  padding: 1rem 2rem;
}
