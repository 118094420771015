/* stylelint-disable-next-line import-notation */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 3% 12%;
}

h1 {
  margin-bottom: 2%;
  font-weight: 500;
  font-size: 24px;
}

.welcomeMessage {
  display: flex;
  justify-content: flex-start;
  margin: 69px 5% 34px;
}

.welcomeMessage h1 {
  font-weight: 500;
  font-size: 24px;
}

.inputsContainer {
  display: flex;
  gap: 20px;
  align-self: center;
  justify-content: flex-start;
  width: 90%;
  height: 50px;
}

.searchBar {
  display: flex;
  width: 90%;
  height: 50px;
  margin-left: 5%;
}

.searchInput {
  width: 93%;
}

.filterButtons > button {
  padding: 2px 8px;
}

.filterButtonsPressed > button {
  padding: 2px 8px;
}

.filterButtons {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterButtonsPressed {
  border-radius: 4px;
}

.filterDropdown {
  display: flex;
  align-items: center;
  height: 28.5px;
  padding: 2px 8px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 0.875rem;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Roboto;
  line-height: 1.75;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  cursor: pointer;
}

.filterDropdown:focus-visible {
  outline: none;
}

.addProjectButton {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.addProjectButton > button {
  background-color: #4f987b;
}

.addProjectButton > button:hover {
  background-color: #478c71;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  margin-bottom: 10%;
}

.tableContainer td,
.tableContainer th {
  text-align: left;
}

.noList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
  margin-left: 20%;
  font-weight: 500;
}

.noListTitle {
  font-size: 28px;
}

.noListMessage {
  margin-top: 20px;
  font-weight: 100;
  font-size: 18px;
  font-style: italic;
}

.error {
  font-weight: 100;
  font-size: 18px;
}

::-webkit-scrollbar {
  display: none;
}

.checkboxInput {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 5%;
}

.option {
  color: var(--radium-blue);
  font-weight: 500;
  cursor: pointer;
}
