.pageContainer {
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem;
}

.bodyContainer {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 2rem 0;
}

.leftSide {
  padding-left: 1.25rem;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 1.25rem;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
}
