.header {
  position: fixed;
  z-index: 20;
  display: flex;
  width: 100%;
  color: #ffffff;
  background-color: var(--radium-blue);
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 18px 0;
  background-color: var(--radium-blue);
}

.menuIcon {
  align-self: center;
  justify-self: flex-end;
  padding-left: 19px;
  background-color: var(--radium-blue);
  cursor: pointer;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-weight: 100;
  font-size: 20px;
  cursor: pointer;
}

.menuLogoContainer img {
  display: inline-block;
  width: 11px;
}

.menu {
  position: fixed;
  top: 70px;
  left: 80px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  box-sizing: border-box;
  width: 180px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 14px;
  background-color: var(--radium-blue);
  border-radius: 0 0 4px 4px;
}

.menu a,
.menu span {
  width: 100%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.arrow {
  padding-right: 20px;
  padding-left: 10px;
  color: transparent;
}

.brand:hover .arrow {
  display: inline;
  color: white;
  transition: 0.1s;
}

.radiumMenuSpan {
  font-weight: 600;
}

.brand :nth-child(2) {
  margin-right: 5px;
  margin-left: 25px;
  font-weight: 600;
}

.navbarContainer {
  display: none;
  font-weight: bold;
  font-size: 1rem;
}

.logout {
  display: flex;
  display: none;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
  color: white;
  font-weight: 400;
  font-size: 15px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--radium-blue);
  border: none;
  cursor: pointer;
}

.logout:hover {
  color: #5bc59b;
}

.logout:hover svg path {
  fill: #5bc59b;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.iconContainer svg {
  height: 18px;
  padding-top: 4px;
}

.logout span {
  margin-right: 5px;
}

@media only screen and (min-width: 713px) {
  .container {
    justify-content: space-between;
    padding: 18px 50px;
  }

  .logout,
  .navbarContainer {
    display: flex;
  }

  .menuIcon {
    display: none;
  }

  .header {
    position: fixed;
  }
}
