.modalTableContainer {
  width: 500px;
  padding: 30px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crossIcon {
  position: absolute;
  margin-top: 10px;
  margin-left: 510px;
  text-align: right;
}

.cancelButton {
  margin-left: 10px;
}
