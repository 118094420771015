.container {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.25rem;
  font-weight: 500;
  font-size: 30px;
  border-bottom: 1px solid #e2e2e2;
}

.options {
  display: flex;
  gap: 1.75rem;
  align-items: center;
}
