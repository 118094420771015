.errorContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-top: 3%;
  font-weight: 500;
}

.textContainer {
  font-size: 28px;
}

.textContainer p {
  margin: 36px 0;
}

.errorMessage {
  margin-top: 20px;
  font-weight: 100;
  font-size: 18px;
}

.errorMessage p:last-child {
  font-style: italic;
}

.errorImg {
  width: 40%;
}
