.tableContainer {
  margin-bottom: 20px;
}

.tableContainer span {
  padding: 3%;
  font-weight: 500;
  font-size: 16px;
}

.table {
  width: 100%;
  margin-top: 3%;
  text-align: center;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.header {
  padding: 13px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 12px;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  padding: 13px;
  color: var(--radium-blue);
  font-size: 12px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.viewMore {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 7px;
}

.notes > div > div > div > fieldset {
  height: 151px;
}

.leftInput {
  margin-bottom: 40px;
}
