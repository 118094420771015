.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.outlet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 70px;
}
