.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkboxMsg {
  margin-left: 2%;
  color: #000000b3;
  font-size: 14px;
  cursor: pointer;
}

.input {
  cursor: pointer;
}
