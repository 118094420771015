/* stylelint-disable-next-line import-notation */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

::-webkit-scrollbar {
  height: 8px;
}

.containerPageBottom,
.containerPageTop {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 89%;
}

.container {
  display: flex;
  margin: 2em;
  margin-top: 3em;
  margin-bottom: 1em;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  width: 100%;
}

.welcomeMessageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 24px;
}

.welcomeMessage {
  width: fit-content;
  margin: 0;
}

.todayDate {
  display: flex;
  font-weight: 500;
  font-size: 20px;
}

.switcherButtons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 1.7%;
}

.filterContainer {
  display: flex;
  flex-shrink: 1;
  align-self: center;
  justify-content: flex-start;
  width: 90%;
  padding: 2px;
  overflow-x: scroll;
  border: 1px solid transparent;
}

.checkboxInput {
  display: flex;
  gap: 14px;
  justify-content: center;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
}

.updateButton {
  padding: 2px;
}

.filterButtons > button,
.filterButtonsSelected > button,
.filterIconButtons > button,
.filterButtonsLarge > button {
  padding: 2px 8px;
}

.filterButtons,
.filterIconButtons,
.filterButtonsLarge {
  width: fit-content;
  height: fit-content;
  margin-left: 2px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterButtonsSelected {
  width: fit-content;
  height: fit-content;
  margin-left: 2px;
  background-color: #0000000f;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterDropdown {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 0.875rem;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Roboto;
  line-height: 1.75;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  cursor: pointer;
}

.filterDropdown:focus-visible {
  outline: none;
}

.noticeMessage {
  display: inline-block;
  height: 0;
  margin-bottom: 40px;
  margin-left: 80px;
  color: var(--radium-blue);
  font-size: 12px;
}

.option {
  color: var(--radium-blue);
  font-weight: 500;
  cursor: pointer;
}

.modalHide {
  display: none;
}

.modalDisplayDesktop {
  position: absolute;
  z-index: 300;
  display: none;
}

.modalDisplayMobile {
  align-self: center;
  margin-top: 10px;
}

.buttons {
  display: flex;
  cursor: pointer;
}

.customTooltip {
  padding: 8px;
  font-size: 14px;
  background-color: #363636;
}

.modalApiKeyContainer {
  padding: 20px;
}

.modalApiKeyContainer p {
  margin-left: 0;
}

.modalApiKeyContainer h1 {
  margin-bottom: 30px;
}

.apiKey {
  display: flex;
  flex-direction: column;
}

.modalApiButtons {
  align-self: center;
  margin-top: 10px;
}

.cancelButton {
  margin-right: 15px;
}

.iconKey {
  margin-left: 10px;
}

.movementsContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  max-height: 712px;
}

.movementsList {
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
}

.movementsContainer select {
  align-self: center;
  margin-bottom: 10px;
}

.historyMovementsDesktopContainer {
  display: none;
}

@media only screen and (min-width: 713px) {
  .movementsContainer select {
    align-self: baseline;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    align-self: flex-start;
  }

  .filterContainer {
    overflow-x: hidden;
  }

  .titleAndKey h1 {
    display: block;
    margin: 0;
    padding-right: 10px;
  }

  .modalApiKeyContainer {
    width: 400px;
    padding: 20px;
  }

  .modalApiButtons {
    align-self: flex-start;
  }

  .cardContainer {
    justify-content: center;
    width: 80%;
  }

  .containerPageBottom {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .historyMovementsDesktopContainer {
    display: inline-block;
    width: 320px;
    margin-right: 15px;
  }

  .historyMovementsTitle {
    color: #0c0c0c;
  }

  .modalDisplayDesktop {
    display: flex;
  }

  .modalDisplayMobile {
    display: none;
  }

  .container {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .switcherButtons {
    display: none;
  }

  .notificationTypeFilter {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    margin-left: 80px;
  }

  .notificationTypeFilter > label {
    width: 30%;
    color: var(--radium-blue);
  }

  .notificationTypeFilter > label > span {
    font-weight: 500;
    font-size: 13px;
  }

  .dayTimeContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .clock {
    display: flex;
    gap: 0.5em;
    align-items: center;
  }
}
