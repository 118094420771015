.modalContainer {
  display: flex;
  flex-direction: column;
}

.headerEditEmployeeSalary {
  font-weight: 500;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
  border-bottom: 1px solid #0000000f;
}

.headerEditEmployeeSalary p {
  margin-bottom: 20px;
  padding-left: 20px;
}

.employeeSalaryForm {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
}

.editSalaryRangesForm {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 15px;
}

.typography {
  width: 150px;
  margin-top: 15px;
  font-weight: 500;
}
