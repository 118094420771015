.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 52px 20px;
}

.welcomeMessage {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  padding-bottom: 21px;
  font-weight: 500;
  font-size: 30px;
  border-bottom: 1px solid #e2e2e2;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.bottom h2 {
  margin-top: 0;
}

.leftSide {
  display: flex;
  flex-direction: column;
}

.backBtn {
  justify-self: flex-end;
}

.topSide,
.bottomSide {
  display: flex;
  width: 100%;
  margin-top: 34px;
}

.bottomSide {
  flex-direction: column;
}

.bottomSide > div {
  display: flex;
}

.bottomSide > button {
  width: fit-content;
}

.feedback {
  width: 50%;
}

.feedback > div {
  width: 90%;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.roleLevel span p {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.roleLevel {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.roleLevel span {
  display: flex;
  width: 187px;
  height: 48px;
  color: #e2e2e2;
  font-size: 16px;
  background-color: var(--radium-blue);
  border-radius: 4px;
}

.titles {
  font-weight: 500;
}

.notesContainer {
  width: 80%;
  margin-top: 5%;
}
