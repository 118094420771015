/* stylelint-disable-next-line import-notation */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 12% 5%;
}

.welcomeMessage {
  margin-top: 5%;
}

.welcomeMessage h1 {
  margin-right: 10px;
  font-weight: 500;
  font-size: 30px;
}

.welcomeMessage p {
  font-weight: 200;
}

.topTableContainer {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}

.tableContainer {
  align-self: center;
  width: 100%;
  margin-bottom: 10%;
}

.tableContainer td,
.tableContainer th {
  text-align: left;
}

.searchBar {
  width: 100%;
}

.filterButtons > button {
  padding: 2px 8px;
}

.filterButtonsPressed > button {
  padding: 2px 8px;
}

.filterButtons {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterButtonsPressed {
  border-radius: 4px;
}

.filterDropdown {
  display: flex;
  align-items: center;
  height: 28.5px;
  padding: 2px 8px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 0.875rem;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Roboto;
  line-height: 1.75;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  cursor: pointer;
}

.filterDropdown:focus-visible {
  outline: none;
}

.buttonsContainer {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  width: 100%;
}

.addCSVButton {
  display: flex;
}

.addCSVButton > button {
  background-color: var(--radium-blue);
}

.addUserButton {
  display: flex;
  justify-content: flex-end;
}

.addUserButton > button {
  background-color: #4f987b;
}

.addUserButton > button:hover {
  background-color: #478c71;
}

::-webkit-scrollbar {
  display: none;
}

.noList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
  margin-left: 20%;
  font-weight: 500;
}

.noListTitle {
  font-size: 28px;
}

.noListMessage {
  margin-top: 20px;
  font-weight: 100;
  font-size: 18px;
  font-style: italic;
}

.searchInput {
  width: 50%;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border: none;
}

input:focus {
  outline: none;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.checkboxInput {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.option {
  color: var(--radium-blue);
  font-weight: 500;
}

.title {
  display: flex;
}

.title:nth-child(1) {
  margin-right: 20px;
}

.modalApiKeyContainer {
  width: 400px;
  padding: 20px;
}

.modalApiKeyContainer p {
  margin-left: 0;
}

.modalApiKeyContainer h1 {
  margin-bottom: 30px;
}

.apiKey {
  display: flex;
  flex-direction: column;
}

.modalApiButtons {
  align-self: flex-end;
  margin-top: 10px;
}

.cancelButton {
  margin-right: 15px;
}

.customTooltip {
  padding: 8px;
  font-size: 14px;
  background-color: #363636;
}
