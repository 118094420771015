.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  align-self: center;
  justify-content: center;
  min-height: 100vh;
}

h1 {
  color: var(--radium-blue);
  font-weight: 300;
  font-size: 25.2308px;
  font-style: normal;
  line-height: 31px;
}

h1 span {
  font-weight: 700;
}
