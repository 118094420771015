.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 30px;
}

.tableContainer span {
  font-weight: 500;
  font-size: 16px;
}

.trContainer {
  display: flex;
  width: 100%;
}

.title {
  margin-bottom: 3%;
}

.table {
  width: 100%;
  text-align: flex-start;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 13px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 13px;
  color: var(--radium-blue);
  font-size: 15px;
}

.iconButton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.editButton {
  cursor: pointer;
}

.noContent {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 5%;
  color: #8d8d8d;
  font-size: 14px;
}

.tableDataContainer {
  max-height: 400px;
  margin: 15px 0;
  overflow-y: auto;
}

.input {
  margin-right: 10px;
  padding: 8px;
  font-size: 15px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;

  &::placeholder {
    color: #cccccc;
    font-style: italic;
  }
}

.input:focus {
  border-color: var(--radium-blue);
  box-shadow: 0 0 0 0.4px #474777;
}

.rowEdit {
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  outline: none;
}

.addContainer {
  display: flex;
  align-items: center;
  height: 25px;
  padding: 5px 0;
}

.rowFocused {
  display: flex;
  width: 100%;
  background-color: #e6e7ec;
}
