.cvContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title h1 {
  color: #000000;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: 0.17px;
}

.title h2 {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.images {
  display: flex;
  width: fit-content;
}

.rrLogo,
.avatar {
  align-self: center;
}

.avatar img {
  width: 150px;
  height: 150px;
  border-radius: 5px;
}

.list {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.listItem {
  display: flex;
  gap: 3px;
  align-items: center;
  height: 25px;
}

.listItem span {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #2b2929;
  border-radius: 50%;
}

.listItem h4 {
  color: #2b2929;
}

.h3 {
  width: fit-content;
  letter-spacing: 0.3px;
}

.footerContainer {
  display: flex;
  justify-content: flex-end;
  height: 26px;
}

.footerContainer div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footerContainer div h5 {
  color: var(--radium-blue);
  font-weight: bold;
  font-size: 25px;
}

.footerContainer div h6 {
  color: var(--radium-blue);
  font-weight: 100;
  font-size: 25px;
}
