.headers th {
  width: 100px;
  font-weight: bolder;
}

.body {
  display: flex;
  flex-direction: column;
}

.table {
  border-right: #d4d4df 0.5px solid;
  border-bottom: #d4d4df 0.5px solid;
  border-left: #d4d4df 0.5px solid;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.buttonCell {
  gap: 10px;
  padding-left: 50px;
}

.inactiveRows {
  color: #000000;
  opacity: 0.3;
}
