.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 552px;
  height: 170px;
  padding: 22px;
  color: #000000de;
  font-size: 16px;
}

p {
  line-height: 26px;
}

.title {
  display: flex;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}
