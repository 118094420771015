.welcomeMessage {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 20px;
}

.welcomeMessage h1 {
  font-weight: 500;
  font-size: 24px;
}

.searchBarAndAvailabilityButton {
  display: flex;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  align-self: center;
}

.searchInput {
  flex: 1;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 1000px;
}

.tableContainer td,
.tableContainer th {
  text-align: left;
}

.formContainer {
  padding: 15px;
}

::-webkit-scrollbar {
  display: none;
}

.buttonsContainer {
  display: flex;
  justify-content: space-around;
}

.searchInput > div {
  width: 68%;
}

input:focus {
  outline: none;
}

.noList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;
  margin-left: 20%;
  font-weight: 500;
}

.noListTitle {
  font-size: 28px;
}

.noListMessage {
  margin-top: 20px;
  font-weight: 100;
  font-size: 18px;
  font-style: italic;
}

.error {
  font-weight: 100;
  font-size: 18px;
}

.checkboxInput {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.filterButtons > button {
  padding: 2px 8px;
}

.filterButtonsPressed > button {
  padding: 2px 8px;
}

.filterButtons {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterButtonsPressed {
  border-radius: 4px;
}

.filterDropdown {
  display: flex;
  align-items: center;
  height: 28.5px;
  padding: 2px 8px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 0.875rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.75;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  cursor: pointer;
}

.filterDropdown:focus-visible {
  outline: none;
}

.option {
  color: var(--radium-blue);
  font-weight: 500;
  cursor: pointer;
}
