.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  width: 550px;
  margin-bottom: 4%;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid #0000000f;
}

.titleContainer p {
  margin-bottom: 20px;
  padding-left: 20px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 550px;
}

.datePicker {
  margin-top: 3%;
  text-align: center;
}

.input {
  width: 150px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  margin-top: 5%;
  margin-right: 3%;
  margin-bottom: 3%;
  text-align: end;
}

.absenceError {
  margin-top: 20px;
  color: red;
  font-size: 14px;
  text-align: center;
}
