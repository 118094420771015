:root {
  /* stylelint-disable-next-line custom-property-pattern */
  --margin-right: 50px;
  --margin-top: 25px;
  --margin-top-container: 20px;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.profileIconEdit {
  position: absolute;
  right: 30%;
  bottom: 20%;
  z-index: 3;
  width: 50px;
  height: 50px;
  color: #1e1e1e;
  background-color: #ffffff;
  border: 1px solid #1e1e1e;
  transform: translate(100%, 80%);
}

.profileForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  width: 80%;
  padding: 10%;
}

.errorP {
  position: absolute;
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

.profileForm > :first-child {
  align-self: center;
}

.profileForm .profileSkillsTitle {
  width: 100%;
}

.profileForm p {
  display: inline-block;
  margin: 0 30px 0 0;
  color: #303030;
  font-weight: bold;
}

.profileForm h1 {
  display: none;
}

.profileSkillsContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--margin-top-container);
}

.profileSkillsItemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.profileInputsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--margin-top);
}

.profileSkillSpan {
  display: inline-block;
  width: max-content;
  height: max-content;
  color: white;
  font-size: 12px;
  background-color: #459474;
  border-radius: 14px;
  pointer-events: none;
  padding-block: clamp(5px, 2vw, 7px);
  padding-inline: clamp(13px, 2vw, 17px);
}

.profileAvatarComponent {
  position: relative;
  width: max-content;
  height: max-content;
}

.profileButtonsContainer {
  display: flex;
  gap: 20px;
  align-self: center;
  margin-top: var(--margin-top-container);
}

.profileButton {
  width: 120px;
}

.profileIconEdit:hover {
  color: #303030;
  background-color: #f3f3f3;
  border: 1px solid #303030;
}

.profileInputFile {
  display: none;
}

.disabledInputs {
  margin-bottom: 20px;
}

.hiddenWhenDesktop {
  display: none;
}

@media only screen and (min-width: 713px) {
  .profileForm {
    flex-wrap: wrap;
    max-width: 650px;
    max-height: 430px;
    padding: 20px 30px;
    padding-right: 50px;
    border-radius: 13px;
    box-shadow: 4px 4px 15px 0 #9c9c9c;
  }

  .profileAvatarComponent {
    order: 2;
    padding-top: 20px;
  }

  .profileForm > :first-child {
    align-self: flex-end;
  }

  .profileForm h1 {
    display: inline-block;
    margin: 0 0 19px;
    padding: 0;
    color: #303030;
  }

  .profileForm .profileSkillsTitle {
    align-self: flex-start;
    margin-bottom: 0 0 30px 54px;
  }

  .profileContainer {
    margin-top: 21px;
  }

  .profileSkillsContainer {
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    order: 3;
    width: 50%;
  }

  .profileSkillsItemsContainer {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .profileInputsContainer {
    width: 30%;
    margin-right: var(--margin-right);
  }

  .profileButtonsContainer {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .disabledInputs,
  .enabledInputs {
    margin-left: 0;
  }
}
