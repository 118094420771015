.lottieContainer > div {
  height: 80% !important;
  margin: 0 !important;
}

.noResults {
  margin-top: 30px;
  color: #666666;
  font-weight: 300;
  font-size: 18px;
}
