.modalOverlay,
.noBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.modalOverlay {
  background-color: #cecbcb80;
  backdrop-filter: blur(0.2px);
}

.loader {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 50px;
  height: 80px;
  background: linear-gradient(#4ea381 30px, transparent 0) no-repeat;
  background-position: 50% 0;
  background-size: 2px 40px;
  border-top: 5px solid var(--radium-blue);
  border-bottom: 5px solid var(--radium-blue);
  animation: spinx 5s linear infinite;
}

.loader::before,
.loader::after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 35px;
  background: #ffffff66;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  border-radius: 0 0 20px 20px;
  transform: translateX(-50%);
  animation: lqt 5s linear infinite;
  content: '';
}

.loader::after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}

@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#4ea381 40px, transparent 0);
    background-position: 0% 0;
  }

  50% {
    background-image: linear-gradient(#4ea381 40px, transparent 0);
    background-position: 0% 40px;
  }

  50.1% {
    background-image: linear-gradient(#4ea381 40px, transparent 0);
    background-position: 0% -40px;
  }
}

@keyframes lqb {
  0% {
    background-image: linear-gradient(#4ea381 40px, transparent 0);
    background-position: 0 40px;
  }

  100% {
    background-image: linear-gradient(#4ea381 40px, transparent 0);
    background-position: 0 -40px;
  }
}

@keyframes spinx {
  0%,
  49% {
    background-position: 50% 36px;
    transform: rotate(0deg);
  }

  51%,
  98% {
    background-position: 50% 4px;
    transform: rotate(180deg);
  }

  100% {
    background-position: 50% 36px;
    transform: rotate(360deg);
  }
}
