.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 30px;
}

.tableContainer span {
  margin-top: 3%;
  margin-bottom: 3%;
  font-weight: 500;
  font-size: 16px;
}

.table {
  width: 100%;
  text-align: flex-start;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.header {
  padding: 13px;
  color: #373867;
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  padding: 13px;
  color: #373867;
  font-size: 15px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.trashButton {
  margin-left: 13%;
  cursor: pointer;
}

.checkAndCrossContainer {
  display: flex;
  gap: 15px;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 3%;
}

.asideIndividualContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.noContent {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 5%;
  color: #8d8d8d;
  font-size: 14px;
}

.modalTableContainer {
  width: 500px;
  padding: 30px;
}
