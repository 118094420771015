.welcomeMessage {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 20px;
}

.welcomeMessage h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
}

.availabilityContainer {
  display: flex;
  flex-direction: column;
  align-self: left;
  width: 90%;
  height: fit-content;
  margin-left: 80px;
}

.individualAvailabilityContainer {
  display: flex;
  flex-direction: column;
  align-self: left;
  width: 65%;
  height: fit-content;
  margin-left: 80px;
}

.checkboxInput {
  display: flex;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.filterButtons > button {
  padding: 2px 8px;
}

.filterButtonsPressed > button {
  padding: 2px 8px;
}

.filterButtons {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.filterButtonsPressed {
  border-radius: 4px;
}

.filterDropdown {
  display: flex;
  align-items: center;
  height: 28.5px;
  padding: 2px 8px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 0.875rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.75;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  cursor: pointer;
}

.option {
  color: var(--radium-blue);
  font-weight: 500;
}

.graphZone {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 100px;
  border: 1px solid #0000001f;
}

.backButton {
  display: flex;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.graphReferences {
  display: flex;
  gap: 20px;
}

.graphReferences div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.graphReferences div p {
  color: #666666;
  font-weight: 500;
  font-size: 13px;
}

.absencesRef {
  width: 12px;
  height: 12px;
  background-color: #4f987b;
}

.absencesPendingRef {
  width: 12px;
  height: 12px;
  background-color: #4bc0c033;
}

.projectsRef {
  width: 12px;
  height: 12px;
  background-color: var(--radium-blue) cc;
}

.vacationsRef {
  width: 12px;
  height: 12px;
  background-color: #4f987b;
}

.studyRef {
  width: 12px;
  height: 12px;
  background-color: #89ccaf;
}

.licenseRef {
  width: 12px;
  height: 12px;
  background-color: #1a4b3b;
}

.searchbar {
  width: 465px;
}
