.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tableContainer span {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
}

.table {
  width: 100%;
  text-align: flex-start;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.header {
  padding: 15px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  padding: 15px;
  color: var(--radium-blue);
  font-size: 13px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.clickableTr {
  text-decoration: underline;
  cursor: pointer;
}

.clickableTr:hover > td {
  font-weight: 500;
}

.projectTr {
  padding: 15px;
  color: var(--radium-blue);
  font-size: 13px;
  text-decoration: underline;
  border: none;
  border-bottom: #d4d4df 2px solid;
  cursor: pointer;
}

.projectTr:hover {
  font-weight: 500;
}

.viewMore {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.asideIndividualContainer {
  display: flex;
  justify-content: space-between;
}

.emptyRows {
  padding: 15px;
  color: #808080;
  font-size: 15px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.titleContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pendingAbsences {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 5px;
  padding: 10px;
  color: white;
  font-size: 13px !important;
  background-color: #459474;
  border-radius: 50%;
}

.customTooltip {
  padding: 8px;
  font-size: 14px;
  background-color: #363636;
}
