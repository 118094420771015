.searchInputContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  padding: 2px;
  border: 1px solid #37386766;
  border-radius: 5px;
}

.searchInput {
  width: 50%;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border: none;
}

input:focus {
  outline: none;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
