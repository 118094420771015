.baseIconTab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 32px;
  margin-left: 15px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 2px 13px 5px #0000001c;
}

.projectTabIcon {
  background: linear-gradient(180deg, #408066 0%, #54aa78 50%, #51a66c 100%);
}

.employeeTabIcon {
  background: linear-gradient(180deg, var(--radium-blue) 0%, #2f4c8f 64.58%, #264f95 100%);
}

.clientTabIcon {
  background: linear-gradient(180deg, #381f3c 0%, #6d4574 63.54%, #492250b5 100%);
}

.highCriticality {
  border: 0.5px solid #cb0707;
}

.mediumCriticality {
  border: 0.5px solid #d6be05;
}

.lowCriticality {
  border: 0.5px solid #95c807;
}

.cardContainer,
.expiredCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16vh;
  padding: 4% 5%;
  color: #7b809a;
  border-radius: 12px;
}

.cardContainer {
  box-shadow: 0 2px 13px 5px #0000001c;
}

.expiredCardContainer {
  color: #7b809a;
  border: 0.7px solid #bb0000;
  box-shadow: 0 2px 13px 5px #ff00001c;
}

.cardComponent {
  width: 80%;
  max-width: 316px;
  margin-top: 10px;
  margin-bottom: 12px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: auto;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
}

.projectInfo {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 14px;
  line-height: 19.6px;
}

.customMessage {
  width: auto;
  font-weight: 300;
  font-size: 14px;
  line-break: anywhere;
}

.notification {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: auto;
  height: 25%;
  font-weight: 200;
  font-size: 16px;
  line-height: 22.4px;
  border-top: 1px solid #f0f2f5;
}

.notification p {
  margin: inherit;
  line-height: normal;
}

.tickIcon {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.customTooltip {
  padding: 8px;
  font-size: 14px;
  background-color: #363636;
}

.nameContainer {
  gap: 6px;
  align-items: center;
  padding-top: 5px;
}

.avatarsContainer {
  display: flex;
  justify-content: flex-start;
}

.membersContainer {
  font-size: 13px;
}

.membersContainer p {
  display: inline;
}

.avatars {
  display: none;
}

.avatars div,
.nameContainer div {
  width: 25px;
  height: 25px;
  margin-bottom: 6px;
  font-size: 10px;
}

.nameContainer div {
  width: 30px;
  height: 30px;
}

.criticality {
  align-items: center;
  height: 16px;
  padding: 0.5% 1%;
  color: #4d4d4d;
  font-size: 12px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 5px;
}

.name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 2px 13px 5px #0000006c;
  cursor: pointer;
}

.expiredCard:hover {
  box-shadow: 0 2px 13px 5px #9a010140;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .avatars {
    display: flex;
  }

  .cardContainer,
  .expiredCardContainer {
    width: 80%;
    height: 185px;
  }

  .cardComponent {
    width: 30%;
  }

  .notification {
    height: 15%;
  }
}
