.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chips {
  margin: 2%;
}

.chipsContainer {
  width: 100%;
  max-height: 130px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: inline !important;
  width: 5px;
  background-color: #0000000f;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #00000017;
  border-radius: 5px;
}
