/* stylelint-disable property-no-vendor-prefix */
.container {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  overflow: hidden;
  color: #7b809a;
  border-radius: 12px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #acacac;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
}

.availability {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 1rem 2rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info span:nth-child(1) {
  font-weight: 700;
  text-transform: uppercase;
}

.info span:nth-child(2) {
  padding-left: 0.5rem;
  font-weight: 400;
}
