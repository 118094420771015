.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.tableContainer {
  width: 100%;
}

.table {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.header {
  padding: 13px;
  color: #00000099;
  font-weight: 500;
  font-size: 14px;
  background-color: #eeeeee96;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  padding: 13px;
  color: #00000099;
  font-size: 14px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.buttons > button {
  min-width: 30px;
  min-height: 15px;
  padding: 4px;
}

.addMembers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1%;
  margin-bottom: 3%;
  font-weight: 500;
  font-size: 24px;
}

.addMembers > button {
  background-color: #4f987b;
}

.addMembers > button:hover {
  background-color: #478c71;
}

.buttons > div {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.emptyMember > div > p {
  margin: 0;
  color: #00000099;
  font-weight: 400;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.emptyMember {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  text-align: center;
}

.emptyMember > :first-child {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.messageContainer {
  margin-bottom: 30px;
}

.addMemberButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
