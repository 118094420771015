.container {
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin-top: 2em;
}

.rows {
  padding: 13px;
  color: var(--radium-blue);
  font-size: 12px;
  text-align: center;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.bottom {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.welcomeMessage {
  display: flex;
  justify-content: flex-start;
  margin: 0 5% 34px;
}

.welcomeMessage h1 {
  font-weight: 500;
  font-size: 24px;
}

.selectors {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  width: 30%;
  padding: 13px;
}

.adjustmentContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.adjustInput {
  display: flex;
  width: 100px;
}

.adjustInput input {
  padding: 13px 14px;
}

.chart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}

.buttonsLeft {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
}

.buttonsLeft button {
  height: fit-content;
}
