.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 52px 20px;
}

.welcomeMessage {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  padding-bottom: 21px;
  font-weight: 500;
  font-size: 30px;
  border-bottom: 1px solid #e2e2e2;
}

.titles {
  margin-top: 40px;
  font-weight: 500;
  font-size: 16px;
}

.feedbackContainer {
  display: flex;
  flex-direction: row;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.rightSide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
}

.skillCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  background-color: #f4f4f4;
  border: 1px solid #afafaf;
  border-radius: 20px;
  box-shadow: 2px 2px 4px #00000040;
}

.cardHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 340px;
  margin-top: 10px;
  font-weight: 500;
}

.cardHeadLeft {
  width: fit-content;
  margin-left: 20px;
  color: var(--radium-blue);
  font-size: 20px;
  text-align: center;
}

.cardHeadRight {
  width: 50px;
  color: #4f987b;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}

.roleLevel span p {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.formElements {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-top: 10px;
  font-size: 14px;
}

.fixedWidth {
  width: 170px;
}

.roleLevel {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.roleLevel span {
  display: flex;
  width: 187px;
  height: 48px;
  color: #e2e2e2;
  font-size: 16px;
  background-color: var(--radium-blue);
  border-radius: 4px;
}

.elementContainer {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.inputForm {
  display: flex;
  width: 50px;
}

.inputForm div input {
  background-color: white;
}

.inputForm div p {
  display: flex;
  justify-content: flex-start;
  width: 93px;
  margin: auto;
}

.textAreaInput {
  margin-top: 20px;
}

.buttonContainer button {
  margin-right: 10px;
}
