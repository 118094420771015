.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 450px;
  height: 430px;
  text-align: center;
  border-radius: 14px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid #c810100f;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 20px;
}

.input {
  width: 220px;
}

.input > div > div > input {
  padding: 7px 14px;
}

.input > div > div > fieldset {
  width: 90%;
  height: 40px;
}

.input > div > div > div > button > svg {
  height: 20px;
  margin-top: 0;
}

.input > div > label {
  margin-top: -8px;
}

.input > div > p {
  margin-top: 2px;
  margin-left: 1px;
}

.textArea > div > div > div {
  background-color: #f6f6f6;
  box-shadow: 0 0 10px 0 #0000001e inset;
}

.textArea > div > div > p {
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
  padding-bottom: 10px;
}
