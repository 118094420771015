.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px 52px;
}

.formContainer {
  display: flex;
  flex-direction: row;
  gap: 3.5%;
  align-items: baseline;
  padding-top: 46px;
}

.handleLeftContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightSide {
  width: 35%;
}

.firstRow,
.secondRow,
.thirdRow,
.fourthRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.thirdColumn {
  align-items: center;
  width: 50%;
}

.elementContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
}

.elementContainer > div {
  height: 100px;
}

.dateSelection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
  margin-bottom: 20px;
}

.dateSelection > div {
  width: 100%;
}

.textInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 47px;
}

.textInput {
  width: 231px;
  height: 40px;
}

.secondWrapperContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 3%;
}

.checkboxContainer {
  width: 34%;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 34%;
  margin-top: 6.3%;
  margin-left: 34px;
}

.rightInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  border: #d4d4df 0.5px solid;
  border-radius: 4px;
  border-collapse: collapse;
  box-shadow: 0 2px 1px -1px #00000033, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  margin-block: 1%;
}

.header {
  padding: 13px;
  color: var(--radium-blue);
  font-weight: 500;
  font-size: 12px;
  border-bottom: #d4d4df 2px solid;
}

.rows {
  padding: 13px;
  color: var(--radium-blue);
  font-size: 12px;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.viewMore {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 7px;
}

.rightInputs > div {
  width: 100%;
  margin-bottom: 50px;
}

.titles {
  font-weight: 500;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.saveButton {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin: 20px 0;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.saveButton > button {
  height: 56px;
}
