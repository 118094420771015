.container {
  width: 90vw;
  margin-top: 2em;
}

.rows {
  padding: 13px;
  color: var(--radium-blue);
  font-size: 12px;
  text-align: center;
  border: none;
  border-bottom: #d4d4df 2px solid;
}

.selectors {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  width: 39%;
}

.chart {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  width: 60%;
}

.bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.roleAndLevelContainer {
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.accordionStyles {
  width: 90%;
}
